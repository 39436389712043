@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap");

@tailwind base;
html {
  scroll-behavior: smooth;
}
@tailwind components;
@layer components {
  .text-between-lines {
    @apply  z-10;
  }

  .line-left {
    @apply inline-block text-center h-1.5 align-middle mr-2 w-[10%] left-[0.5em]  bg-[#0060ff];
  }

  .line-right {
    @apply inline-block text-center h-1.5 align-middle ml-2 w-[10%] left-[0.5em]  bg-[#d41c30];
  }

  .service-container {
    @apply bg-[#212121] text-white w-[90%] md:w-[80%] my-16 md:py-4 2xl:my-24 2xl:h-[390px];
  }

  .active {
    @apply pb-1 border-b-2 border-[#d41c30];
  }
}
@tailwind utilities;

p {
  font-family: 'Roboto', sans-serif;
}

#menu-toggle:checked + #menu{
    display: block;
}

.navicon {
    width: 1.125em;
    height: .125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .2s ease-out;
    content: '';
    background: #ffff;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }

  
  
